import Image from 'next/image';

import { ImageFragment } from '@hultafors/solidgear/types';

import {
  ContentWrapper,
  CtaBox,
  CtaText,
  GenericPlugStyled,
} from './GenericPlug.styled';

interface GenericPlugProps {
  text: string;
  image?: ImageFragment;
  url: string;
  sizes?: string;
}

export const GenericPlug: React.FC<GenericPlugProps> = ({
  text,
  url,
  image,
  sizes = '100vw',
}) => {
  return (
    <GenericPlugStyled href={url} $image={!!image?.responsiveImage?.src}>
      {image?.responsiveImage?.src && (
        <Image
          src={image.responsiveImage.src || ''}
          alt={image.alt || text}
          fill
          sizes={sizes}
          className="Image"
          placeholder="blur"
          blurDataURL={image.responsiveImage.base64 || undefined}
        />
      )}

      <ContentWrapper>
        {text && (
          <CtaBox>
            <CtaText>{text}</CtaText>
          </CtaBox>
        )}
      </ContentWrapper>
    </GenericPlugStyled>
  );
};
