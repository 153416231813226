import { breakpoints } from '@hultafors/shared/constants';

import { constructPlugUrl } from '@hultafors/solidgear/helpers';
import { GenericPlugBlockFragment } from '@hultafors/solidgear/types';

import { GenericPlug } from '../GenericPlug/GenericPlug';

import { GenericPlugBlockStyled } from './GenericPlugBlock.styled';

export const GenericPlugBlock: React.FC<GenericPlugBlockFragment> = ({
  fullwidth,
  plugs,
}) => {
  // TODO merge several types of plugTypes
  const plugMapper = (plug: any, index: number) => {
    const key = `Plug-${index}`;
    const link = constructPlugUrl(
      plug.__typename,
      plug?.parentSlug,
      plug?.slug,
      plug?.link,
    );

    const text = plug?.heroTitle || plug?.title || plug?.ctaText;
    if (link && text) {
      return (
        <GenericPlug
          text={text}
          image={plug?.image || plug.heroImage}
          url={link}
          key={key}
          sizes={`100vw, (min-width: ${breakpoints.tablet}) 50vw`}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <GenericPlugBlockStyled $fullWidth={fullwidth}>
      {plugs.map(plugMapper)}
    </GenericPlugBlockStyled>
  );
};
