import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

import { RouterLink } from '../RouterLink/RouterLink';

interface GenericPlugStyledProps {
  $image: boolean;
}

export const GenericPlugStyled = styled(RouterLink)<GenericPlugStyledProps>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  background: ${({ $image }) => ($image ? 'none' : colors.black)};
  aspect-ratio: 1 / 1;

  a {
    &:hover {
      img {
        opacity: 0.95;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
  width: 100%;
  height: 100%;
`;

export const CtaBox = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: ${spacing.medium};
  padding: 0 ${spacing.regular};
  background-color: ${colors.gray5};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
  }
`;

export const CtaText = styled.div`
  appearance: none;
  max-width: 350px;
  min-width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.small} ${spacing.medium};
  box-sizing: border-box;
  font-size: ${fontSizes.header3Content};
  line-height: ${lineHeights.header3Content};
  font-weight: ${fontWeights.fontMedium};
  background-color: ${colors.gray5};
  color: ${colors.solidBlack};
`;
