import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/solidgear/helpers';

interface GenericPlugBlockStyledProps {
  $fullWidth: boolean;
}

export const GenericPlugBlockStyled = styled.div<GenericPlugBlockStyledProps>`
  display: grid;

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    max-width: ${({ $fullWidth }) => !$fullWidth && 'calc(100vw - 40px)'};
    margin-inline: auto;
    > span {
      margin: ${({ $fullWidth }) => ($fullWidth ? 'none' : spacing.medium)};
    }
  }
`;
